.masonaryGrid {
  --gap: 1em;
  --columns: 3;
  margin: 0 auto;
  columns: var(--columns);
  gap: var(--gap);
}

.masonaryGrid > * {
  break-inside: avoid;
  margin-bottom: var(--gap);
}

@supports (grid-template-rows: masonry) {
  .masonaryGrid {
    display: grid;
    grid-template-columns: repeat(var(--columns), 1fr);
    grid-template-rows: masonry;
    grid-auto-flow: dense;
    /* align-tracks: stretch; */
  }

  .masonaryGrid > * {
    margin-bottom: 0em;
  }
}

.featured {
  grid-column: span 2;
}

@media (max-width: 700px) {
  .masonaryGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(358px, 1fr));
    grid-auto-flow: dense;
    grid-gap: 1rem;
  }
  .masonaryGrid > * {
    margin-bottom: 0em;
  }
}
