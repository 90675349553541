.styles_masonaryGrid__1gyz_ {
  --gap: 1em;
  --columns: 3;
  margin: 0 auto;
  columns: var(--columns);
  gap: var(--gap);
}

.styles_masonaryGrid__1gyz_ > * {
  page-break-inside: avoid;
  break-inside: avoid;
  margin-bottom: var(--gap);
}

@supports (grid-template-rows: masonry) {
  .styles_masonaryGrid__1gyz_ {
    display: grid;
    grid-template-columns: repeat(var(--columns), 1fr);
    grid-template-rows: masonry;
    grid-auto-flow: dense;
    /* align-tracks: stretch; */
  }

  .styles_masonaryGrid__1gyz_ > * {
    margin-bottom: 0em;
  }
}

.styles_featured__KOtMO {
  grid-column: span 2;
}

@media (max-width: 700px) {
  .styles_masonaryGrid__1gyz_ {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(358px, 1fr));
    grid-auto-flow: dense;
    grid-gap: 1rem;
  }
  .styles_masonaryGrid__1gyz_ > * {
    margin-bottom: 0em;
  }
}

@font-face {
font-family: 'mainFont';
src: url(/_next/static/media/9c4f919b87139750-s.p.ttf) format('truetype');
font-display: swap;
}

@font-face {
font-family: 'mainFont';
src: url(/_next/static/media/338b22e5dbda332f-s.p.woff) format('woff');
font-display: swap;
}

@font-face {
font-family: 'mainFont';
src: url(/_next/static/media/3cd7676fe843df6b-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: 'mainFont Fallback';src: local("Arial");ascent-override: 78.65%;descent-override: 20.66%;line-gap-override: 0.00%;size-adjust: 100.70%
}.__className_963434 {font-family: 'mainFont', 'mainFont Fallback'
}

